(function () {
	'use strict';

	angular
		.module('app')
		.controller('AppCtrl', [
			'$scope',
			'$rootScope',
			'$timeout',
			'$parse',
			'$sce',
			'calendarEvents',
			'environment',
			'utilities',
			'seedcodeCalendar',
			'dataStore',
			'manageMeasure',
			'manageTheme',
			AppCtrl,
		]);

	function AppCtrl(
		$scope,
		$rootScope,
		$timeout,
		$parse,
		$sce,
		calendarEvents,
		environment,
		utilities,
		seedcodeCalendar,
		dataStore,
		manageMeasure,
		manageTheme
	) {
		let appLoadStart = false;
		let appLoadFinish = false;
		$scope.loadingApp = true;
		// If the route is going to change then we cancel loading events
		$scope.$on('$routeChangeStart', function () {
			calendarEvents.cancelEventFetch();
		});

		$scope.$on('$routeChangeSuccess', function ($event, next, current) {
			//Load the chart if we were showing it and we navigate to the calendar
			if (
				$scope.calendarInfoShown &&
				(next.$$route.originalPath === '/' ||
					next.$$route.originalPath === '/shared/:id?')
			) {
				var showOnRender = $scope.$on(
					'eventsRendered',
					function (ev, options) {
						if (options.schedulesLoaded) {
							// Stop watching for render
							showOnRender();
							if (!environment.isPhone || environment.isShare) {
								$scope.$evalAsync(function () {
									$scope.measureDetails();
								});
							}
						}
					}
				);
			}
		});

		$scope.$on('loading-settings', function (event, data) {
			$scope.loadingSettings = data;
			if (!!data && !appLoadStart) {
				// App has started loading and fetching settings
				$scope.loadingApp = true;
				appLoadStart = true;
			} else {
				if (!appLoadFinish) {
					// App has finished loading settings and will show the calendar
					$scope.loadingApp = false;
					appLoadFinish = true;
					manageTheme.setBodyTheme();
				}
			}
		});

		//Whatch for calendarInfo broadcast. We use this so we can adjust the position of the calendar or make other css changes
		$scope.$on('calendarInfo', function (event, data) {
			if (!environment.isPhone || environment.isShare) {
				$scope.calendarInfoShown = data;
			}
		});

		//Whatch for calendarInfo settings broadcast. We use this so we can adjust the position of the calendar or make other css changes
		$scope.$on('calendarInfoSettings', function (event, data) {
			if (!environment.isPhone) {
				$scope.calendarInfoSettingsShown = data;
			}
		});

		$scope.$on('view', function (event, data) {
			$scope.calendarFooterShown =
				data.name !== 'month' &&
				data.name !== 'agendaDays' &&
				(!environment.isPhone || environment.isShare)
					? true
					: false;
			// $scope.calendarFooterShown = data.name === 'basicHorizon' && !environment.isPhone ? true : false;
			//Hide the chart if navigating to a view that doesn't support it
			if ($scope.calendarInfoShown && !$scope.calendarFooterShown) {
				manageMeasure.hideMeasure();
			}
		});

		$scope.$on('alt-view', function (event, data) {
			updateAltView(data);
		});

		$scope.closeCalendarInfo = manageMeasure.hideMeasure;
		$scope.measureDetails = manageMeasure.showMeasure;

		function updateAltView(options) {
			const enabled = options.enabled;
			const show = options.show;
			const filter = options.unscheduledFilter;
			const filterFromURL = options.filterFromURL;
			const type = options.type;
			const width = options.width;

			const altViewMethods = seedcodeCalendar.get('altViewMethods');
			const altView = seedcodeCalendar.get('alt-view');
			const config = seedcodeCalendar.get('config');
			const isShare = environment.isShare;

			$scope.unscheduledEnabled = !isShare && enabled;
			$scope.altViewType = type;

			// Update unscheduled text filter
			if (filterFromURL && altViewMethods?.updateUnscheduledFilter) {
				altViewMethods.updateUnscheduledFilter(filter);
				delete altView.unscheduledFilter;
				delete altView.filterFromURL;
			}

			config.showAltView = show;
			seedcodeCalendar
				?.get('element')
				?.fullCalendar('updateOptions', 'showAltView', show, false);

			if (altViewMethods?.updateAltView) {
				altViewMethods.updateAltView({show, type, width});
			}
			dataStore.saveState('showAltView', show);
			dataStore.saveState('altViewType', type);
			dataStore.saveState('altViewWidth', width);
			if (type === 'map') {
				const mapShownCallback =
					calendarEvents.getReportingCallback('mapShown');
				if (mapShownCallback) {
					mapShownCallback(show);
				}
			}
		}
	}
})();
